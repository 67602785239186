class localStorageService {
  ls = window.localStorage;

  setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  getItem(key) {
    let value = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }
  getUserId = () => {
    let value = this.ls.getItem("auth_user");
    try {
      return JSON.parse(value).userId;
    } catch (e) {
      return null;
    }
  };
  getLang = () => {
    // return "ar"
    try {
      let value = this.ls.getItem("auth_user");
      if (!value) return "ar";
      let res = JSON.parse(value).lang;
      if (res) return res;
      else return "ar";
    } catch {
      return "ar";
    }
  };
}

export default new localStorageService();
