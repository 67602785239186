import { SET_LAYOUT_SETTINGS, SET_DEFAULT_LAYOUT_SETTINGS/*, SET_LOGGEDIN_USER_FULLNAME*/ } from "../actions/LayoutActions";
import { GullLayoutSettings } from "../../GullLayout/settings";

const initialState = {
  settings: {
    ...GullLayoutSettings,
  },
  defaultSettings: {
    ...GullLayoutSettings,
  },
  //fullName: "",//Imporve it by using user reducer instead
};

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_SETTINGS:
      return {
        ...state,
        settings: { ...action.data },
      };
    case SET_DEFAULT_LAYOUT_SETTINGS:
      return {
        ...state,
        defaultSettings: { ...action.data },
      };
    // case SET_LOGGEDIN_USER_FULLNAME://Imporve it by using user reducer instead
    //   return {
    //     ...state,
    //     fullName: action.data,
    //   };
    default:
      return { ...state };
  }
};

export default LayoutReducer;
