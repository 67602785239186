
import AuthGuard from "./auth/AuthGuard";
import allRoutes from "./allRoutes";

const routes = [
  ...allRoutes.anonymousRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [...allRoutes.securedRoutes, allRoutes.redirectRoute, allRoutes.errorRoute],
  },
];

export default routes;
