/*
The following routes are used in two places:
//In the navigations.js file to be displayed to the user or not according to his role.
2-In routes.js file to be checked against the loading of the page to prevent url navigation.
*/
export const authRoles = {
  sa: ["SuperAdmin"],
  AqaryOnly: ["Aqary"],
  dataEntry: ["DataEntry"],
  nonDataEntry: ["SuperAdmin", "Aqary",],
  all: ["SuperAdmin", "Aqary","DataEntry"],
};
