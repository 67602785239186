export const SET_LAYOUT_SETTINGS = "LAYOUT_SET_SETTINGS";
export const SET_DEFAULT_LAYOUT_SETTINGS = "LAYOUT_SET_DEFAULT_SETTINGS";
//export const SET_LOGGEDIN_USER_FULLNAME = "SET_LOGGEDIN_USER_FULLNAME";//Imporve it by using user reducer instead


export const setLayoutSettings = data => dispatch => {
  dispatch({
    type: SET_LAYOUT_SETTINGS,
    data: data
  });
};

export const setDefaultSettings = data => dispatch => {
  dispatch({
    type: SET_DEFAULT_LAYOUT_SETTINGS,
    data: data
  });
};

// export const setLoggedInUserFullName = data => dispatch => {//Imporve it by using user reducer instead
//   console.log("setLoggedInUserFullName data",data);
//   dispatch({
//     type: SET_LOGGEDIN_USER_FULLNAME,
//     data: data
//   });
// };