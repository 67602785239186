import axios from "axios";
import localStorageService from "./localStorageService";

class JwtAuthService {

  user = {
    userId: "1",
    userType: "Aqary",
    fullName: "",
    userName: "",
    imageUrl: "/assets/images/face-7.jpg",
    //age: 25,
    token: "",
  };

  setSessionAndUser = (responseData) => {
      this.setSession(responseData.accessToken);
      this.setUser(responseData);
      window.location = '/dashboard/v1'
  };

  // loginWithEmailAndPassword = (userName, password,lang) => {
  //   alert('kama')
  //   return http.post(process.env.REACT_APP_BASEURL +"users/login?lang="+lang, {mobile: userName, password }).then((response) => {
  //     this.setSession(response.data.data.accessToken);
  //     this.setUser(response.data.data);
  //     window.location = '/dashboard/v1'
  //     return response.data;
  //   });
  // };


  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(localStorageService.getItem("auth_user"));
      }, 100);
    }).then((data) => {
      if (data) { 
        this.setSession(data.accessToken);
        // this.setLang(data.lang);
      } else { this.setSession(null)}
      this.setUser(data);
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
    window.location =  "/session/signin";
  };

  setSession = (token) => {
    if (token) {
      //alert(token)
      //localStorage.setItem("accessToken", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      //alert('no token')
      //localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new JwtAuthService();
