import { lazy } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth/authRoles';

const Dashboard1 = lazy(() =>
  import('./views/dashboard/dashboard1/Dashboard1')
);
const news = lazy(() => import('./views/news/news'));
const unitsReports = lazy(() => import('./views/unitsReports/unitsReports'));
const newForm = lazy(() => import('./views/news/newsForm'));
const AccountSettings = lazy(() => import('./views/sessions/AccountSettings'));
const ProfileSettings = lazy(() => import('./views/sessions/ProfileSettings'));
const units = lazy(() => import('./views/units/units'));
const unitForm = lazy(() => import('./views/units/unitForm'));
const unitsAddByUserInPeriod = lazy(() =>
  import('./views/reports/unitsAddedByUserInPeriod')
);
const notifications = lazy(() => import('./views/notifications/notifications'));
const users = lazy(() => import('./views/users/users'));
const userForm = lazy(() => import('./views/users/userForm'));

const securedRoutes = [
  {
    path: '/dashboard/v1',
    component: Dashboard1,
    auth: authRoles.all,
  },
  {
    path: '/news/list',
    component: news,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/news/:id', //having the id in the segment part here prevents the auth from being applied; a custom solution should be added to handle it for urls without the parameters.
    component: newForm,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/unitsReports/list',
    component: unitsReports,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/session/account-settings',
    component: AccountSettings,
    exact: true,
  },
  {
    path: '/session/my-profile',
    component: ProfileSettings,
    exact: true,
  },
  {
    path: '/units/list',
    component: units,
    auth: authRoles.all,
    exact: true,
  },
  {
    path: '/unit/:id', //having the id in the segment part here prevents the auth from being applied; a custom solution should be added to handle it for urls without the parameters.
    component: unitForm,
    auth: authRoles.all,
    exact: true,
  },
  {
    path: '/reports/unitsAddByUserInPeriod',
    component: unitsAddByUserInPeriod,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/notifications/list',
    component: notifications,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/users/list',
    component: users,
    auth: authRoles.sa,
    exact: true,
  },
  {
    path: '/users/:id/:type', //having the id in the segment part here prevents the auth from being applied; a custom solution should be added to handle it for urls without the parameters.
    component: userForm,
    auth: authRoles.sa,
    exact: true,
  },
];

const Signup = lazy(() => import('./views/sessions/Signup'));
const Signin = lazy(() => import('./views/sessions/Signin'));
const ForgotPassword = lazy(() => import('./views/sessions/ForgotPassword'));
const ConfirmCode = lazy(() => import('./views/sessions/ConfirmCode'));
const ResetPassword = lazy(() => import('./views/sessions/ResetPassword'));
const Terms = lazy(() => import('./views/termsPolicy/terms'));
const PrivacyPolicy = lazy(() => import('./views/termsPolicy/privacyPolicy'));

const anonymousRoutes = [
  {
    path: '/session/signup',
    component: Signup,
  },
  {
    path: '/session/signin',
    component: Signin,
  },
  {
    path: '/session/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/session/confirm-code',
    component: ConfirmCode,
  },
  {
    path: '/session/reset-password/:token',
    component: ResetPassword,
  },
  {
    path: '/terms',
    component: Terms,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
];

const redirectRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to='/dashboard/v1' />,
};

const errorRoute = {
  component: () => <Redirect to='/session/404' />,
};

export default {
  securedRoutes,
  anonymousRoutes,
  redirectRoute,
  errorRoute,
};
