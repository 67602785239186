import layout1Settings from "./Layout1/Layout1Settings";
import localStorageService from "../services/localStorageService";
const lang = localStorageService.getLang();
export const GullLayoutSettings = {
  activeLayout: "layout1", // layout1, layout2
  dir: lang === "ar" ? "rtl" : "ltr", // ltr, rtl
  lang: lang,
  layout1Settings,
  customizer: {
    show: false,
    open: false,
  },
  footer: {
    show: false,
  },
};
